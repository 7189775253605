<template>
	<div class="lms-apply">
		<nav-bar :nav-settings="navSettings"></nav-bar>
		<section v-if="applicationOpen" class="recruiting lms-section lms-recruiting-animation">
			<div class="lms-content-frame centered lms-section-head">
				<img src="@/assets/IllustrationLeft.svg" alt />
				<div class="text-box">
					<img class="mobile" src="@/assets/apply_woman.svg" alt />
					<h2> {{ getContent.applyPage.main_title[0][getLanguage] }}
						<span>{{ getContent.applyPage.main_title[1][getLanguage] }}</span>
					</h2>
					<p>
						{{ getContent.applyPage.application_description[getLanguage] }}
						<br><br>
						{{ getContent.applyPage.application_deadline_description[0][getLanguage] }}
						<b>{{ getContent.applyPage.application_deadline[getLanguage] }} </b>
						{{ getContent.applyPage.application_deadline_description[1][getLanguage] }}
					</p>
					<router-link tag="button" to="/application" class="lms-main-button transparent">
						{{ getContent.applyPage.button[getLanguage] }}
					</router-link> &nbsp;&nbsp;
					<router-link tag="button" to="/faq" class="lms-main-button transparent ">
						{{ getContent.applyPage.faq_button_text[getLanguage] }}
					</router-link>


				</div>
				<img src="@/assets/IllustrationRight.svg" alt />
			</div>
			<button v-scroll-to="{ el: '.why-join', offset: -50, }" class="scroll-down">
				<img src="@/assets/arrow-down-circle.svg" alt />
			</button>

		</section>
		<section v-if="!applicationOpen" class="application lms-section">
			<div class="lms-content-frame vertical centered">
				<div class="lms-apply-box">
					<div class="step">
						<div class="instructions">
							<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
								<path
									d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Zm0,2a9,9,0,1,0,9,9A9,9,0,0,0,11,2Zm0,2a7,7,0,1,1-7,7A7,7,0,0,1,11,4Zm0,2a5,5,0,1,0,5,5A5,5,0,0,0,11,6Zm0,2a3,3,0,1,1-3,3A3,3,0,0,1,11,8Zm0,2a1,1,0,1,0,1,1A1,1,0,0,0,11,10Z" />
							</svg>
							<h3>{{ getContent.applyPage.application_closed_part[0][getLanguage] }}</h3>

							<p> {{ getContent.applyPage.application_closed_part[1][getLanguage] }}</p>
							<br>
							<router-link tag="button" to="/faq" class="lms-main-button transparent ">
								{{ getContent.applyPage.faq_button_text[getLanguage] }}
							</router-link>
							<br><br>
							<p>{{ getContent.applyPage.reminder[getLanguage] }}</p>
							<br>
							<section id="notify" class="notify">
								<div class="lms-content-frame">
									<div class="application-notification-form">
										<input v-model="notificationName" type="text" class="lms-main-input" name="name"
											:placeholder="getContent.contact.namePlaceholder[getLanguage]" />
										<input v-model="notificationEmail" type="email" class="lms-main-input"
											name="email"
											:placeholder="getContent.contact.emailPlaceholder[getLanguage]" />

										<button v-if="!notificationSent" type="button" class="lms-main-button big"
											id="applyNotifyButton" name="sendApplicationNotification"
											@click="sendApplicationNotification">
											{{ getContent.contact.button[0][getLanguage] }}
										</button>
										<div class="applicationNotificationDiv" v-else>
											<p v-if="!notificationError">{{ getContent.contact.button[1][getLanguage] }}
											</p>
											<p class="error-message" v-else>{{ getContent.contact.button[2][getLanguage]
												}}</p>
										</div>

									</div>
								</div>
							</section>
						</div>

					</div>
				</div>
			</div>
		</section>
		<section class="why-join lms-section">
			<div class="lms-content-frame vertical">
				<div class="lms-section-head">
					<h2>
						{{ getContent.applyPage.why_part[0][getLanguage] }}
						<span>{{ getContent.applyPage.why_part[1][getLanguage] }}</span>
						{{ getContent.applyPage.why_part[2][getLanguage] }}
					</h2>
					<p>{{ getContent.applyPage.why_part[3][getLanguage] }}</p>
				</div>
				<div class="lms-info-grid">
					<div class="grid-item">
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="21.884" height="21.867"
								viewBox="0 0 21.884 21.867">
								<g transform="translate(-0.058 -0.066)">
									<path
										d="M9.151,7l.274.013A6,6,0,0,1,13.8,9.4a1,1,0,0,1-1.6,1.2,4,4,0,0,0-5.864-.591l-.168.159L3.179,13.155a4,4,0,0,0,5.479,5.818l.164-.15,1.71-1.71a1,1,0,0,1,1.5,1.32l-.083.094-1.722,1.722a6,6,0,0,1-8.647-8.307l.176-.19,3-3A6,6,0,0,1,9.151,7ZM20.186,1.824a6,6,0,0,1,.237,8.234l-.176.19-3,3A6,6,0,0,1,8.2,12.6a1,1,0,1,1,1.6-1.2,4,4,0,0,0,5.864.591l.168-.159,2.988-2.988A4,4,0,0,0,13.34,3.029l-.165.15-1.72,1.71A1,1,0,0,1,9.961,3.565l.084-.094,1.73-1.72A6,6,0,0,1,20.186,1.824Z" />
								</g>
							</svg>
							<h3>{{ getContent.applyPage.network_part[0][getLanguage] }}</h3>
						</div>
						<p>
							{{ getContent.applyPage.network_part[1][getLanguage] }}
						</p>
					</div>
					<div class="grid-item">
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
								<path
									d="M17,0a1,1,0,0,1,1,1h0V21a1,1,0,0,1-1,1H3.5A3.5,3.5,0,0,1,0,18.5H0V3.5A3.5,3.5,0,0,1,3.5,0H17ZM16,17H3.5a1.5,1.5,0,0,0-1.493,1.355L2,18.5A1.5,1.5,0,0,0,3.5,20H16ZM16,2H3.5A1.5,1.5,0,0,0,2.007,3.356h0L2,3.5V15.337a3.481,3.481,0,0,1,1.308-.332L3.5,15H16Z" />
							</svg>
							<h3>{{ getContent.applyPage.education_part[0][getLanguage] }}</h3>
						</div>
						<p>
							{{ getContent.applyPage.education_part[1][getLanguage] }}
						</p>
					</div>
					<div class="grid-item">
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22.078" viewBox="0 0 20 22.078">
								<g transform="translate(0 -0.002)">
									<path
										d="M8.5.4a3,3,0,0,1,3,0h0l7,4a3,3,0,0,1,.935.846,1.019,1.019,0,0,1,.16.209,1,1,0,0,1,.089.2A3.013,3.013,0,0,1,20,7h0v8a3,3,0,0,1-1.5,2.6h0l-7,4a3,3,0,0,1-1.027.365,1,1,0,0,1-.947,0A2.987,2.987,0,0,1,8.5,21.6h0l-7-4A3,3,0,0,1,0,15H0V7A3,3,0,0,1,.314,5.666,1.008,1.008,0,0,1,.565,5.25,2.987,2.987,0,0,1,1.5,4.4h0ZM2,7.537V15a1,1,0,0,0,.5.863h0L9,19.579V11.586Zm16,0-7,4.049v7.991l6.5-3.713a1,1,0,0,0,.493-.747L18,15Zm-7.5-5.4a1,1,0,0,0-1,0h0L3.039,5.828,10,9.854l6.96-4.026Z" />
								</g>
							</svg>
							<h3>{{ getContent.applyPage.project_part[0][getLanguage] }}</h3>
						</div>
						<p>
							{{ getContent.applyPage.project_part[1][getLanguage] }}
						</p>
					</div>
					<img class="mobile" src="@/assets/people_sitting.svg" alt />
				</div>
			</div>
		</section>
		<section class="what-we-look-for lms-section">
			<div class="lms-content-frame vertical centered">
				<div class="lms-section-head">
					<h2>
						{{ getContent.applyPage.wesearch_part[0][getLanguage] }}
						<span>{{ getContent.applyPage.wesearch_part[1][getLanguage] }}</span>
						{{ getContent.applyPage.wesearch_part[2][getLanguage] }}
					</h2>
					<p>
						{{ getContent.applyPage.wesearch_part[3][getLanguage] }}
						<br>
						{{ getContent.applyPage.wesearch_part[4][getLanguage] }}
					</p>
				</div>
			</div>
		</section>

		<Footer></Footer>
	</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import * as ManualCookies from '@/assets/js/cookie-functions.js'

export default {
	components: {
		NavBar,
		Footer,
	},
	computed: {
		getLanguage() {
			return this.$store.state.language;
		},
		getContent() {
			return this.$store.state;
		},
		navSettings() {
			return [
				{
					title: this.getContent.navTitles[0][this.getLanguage],
					path: "/#why-lumos",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><path d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Zm0,2a9,9,0,1,0,9,9A9,9,0,0,0,11,2Zm0,8a1,1,0,0,1,.993.883L12,11v4a1,1,0,0,1-1.993.117L10,15V11A1,1,0,0,1,11,10Zm.01-4a1,1,0,0,1,.117,1.993L11,8a1,1,0,0,1-.117-1.993Z"/></svg>',
					scrollSettings: {
						el: ".why-lumos",
						offset: -120,
					},
				},
				{
					title: this.getContent.navTitles[1][this.getLanguage],
					path: "/#services",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M19,11a1,1,0,0,1,1,1h0v7a1,1,0,0,1-1,1H12a1,1,0,0,1-1-1h0V12a1,1,0,0,1,1-1h7ZM8,11a1,1,0,0,1,1,1H9v7a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1H0V12a1,1,0,0,1,1-1H8Zm10,2H13v5h5ZM7,13H2v5H7ZM8,0A1,1,0,0,1,9,1H9V8A1,1,0,0,1,8,9H1A1,1,0,0,1,0,8H0V1A1,1,0,0,1,1,0H8ZM19,0a1,1,0,0,1,1,1h0V8a1,1,0,0,1-1,1H12a1,1,0,0,1-1-1h0V1a1,1,0,0,1,1-1h7ZM7,2H2V7H7ZM18,2H13V7h5Z"/></svg>',
					scrollSettings: {
						el: ".our-services",
						offset: 50,
					},
				},
				{
					title: this.getContent.navTitles[2][this.getLanguage],
					path: "/apply",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20"><path d="M13,12a5,5,0,0,1,5,4.783L18,17v2a1,1,0,0,1-1.993.117L16,19V17a3,3,0,0,0-2.824-2.995L13,14H5a3,3,0,0,0-2.995,2.824L2,17v2a1,1,0,0,1-1.993.117L0,19V17a5,5,0,0,1,4.783-5L5,12Zm7.25.162a5,5,0,0,1,3.745,4.611L24,17v2a1,1,0,0,1-1.993.117L22,19V17a3,3,0,0,0-2.25-2.9,1,1,0,1,1,.5-1.936ZM9,0A5,5,0,1,1,4,5,5,5,0,0,1,9,0Zm7.248.161a5,5,0,0,1,0,9.688,1,1,0,0,1-.5-1.937,3,3,0,0,0,0-5.812,1,1,0,0,1,.5-1.938ZM9,2a3,3,0,1,0,3,3A3,3,0,0,0,9,2Z"/></svg>',
					scrollSettings: {},
				},
				{
					title: this.getContent.navTitles[3][this.getLanguage],
					path: "/blog",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="22" height="19.999" viewBox="0 0 22 19.999"><path d="M7,0a4.992,4.992,0,0,1,4,2,4.991,4.991,0,0,1,4-2h6a1,1,0,0,1,1,1h0V16a1,1,0,0,1-1,1H14a2,2,0,0,0-2,2,.949.949,0,0,1-.845.989l-.1.01h-.1A.957.957,0,0,1,10,19h0l-.005-.149A2,2,0,0,0,8,17H1a1,1,0,0,1-1-1H0V1A1,1,0,0,1,1,0H7ZM7,2H2V15H8a3.982,3.982,0,0,1,1.855.455h0l.145.081V5h0l-.005-.176A3,3,0,0,0,7.176,2.005h0ZM20,2H15a3,3,0,0,0-3,3h0V15.535l.145-.08A3.977,3.977,0,0,1,13.8,15h0l.2,0h6Z"/></svg>',
					scrollSettings: {},
				},

				{
					title: this.getContent.navTitles[4][this.getLanguage],
					path: "/team",
					icon:
						'<svg id="Layer_1" viewBox="16 24 60 45" width="25" height="26" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><g><path d="M46.252,22.97c-6.738,0-12.219,5.484-12.219,12.223c0,2.725,0.907,5.236,2.42,7.27c-2.98,2.084-4.777,5.191-4.777,8.521   v17.369h3.4V50.983c0-2.371,1.436-4.619,3.804-6.068c2.052,1.561,4.601,2.498,7.372,2.498c2.806,0,5.385-0.959,7.451-2.557   c2.427,1.449,3.897,3.719,3.897,6.127v17.369H61V50.983c0-3.383-1.839-6.516-4.89-8.598c1.479-2.021,2.363-4.504,2.363-7.193   C58.474,28.454,52.991,22.97,46.252,22.97z M46.252,44.015c-4.863,0-8.818-3.959-8.818-8.822c0-4.865,3.955-8.822,8.818-8.822   c4.864,0,8.822,3.957,8.822,8.822C55.074,40.056,51.116,44.015,46.252,44.015z"/><path d="M18.976,48.233c0-1.043,0.596-2.037,1.579-2.768c1.26,0.838,2.768,1.33,4.391,1.33c4.394,0,7.969-3.572,7.969-7.965   c0-4.395-3.575-7.969-7.969-7.969c-4.393,0-7.965,3.574-7.965,7.969c0,1.529,0.453,2.943,1.202,4.156   c-1.642,1.359-2.607,3.242-2.607,5.246v10.084h3.4V48.233z M24.945,34.263c2.52,0,4.568,2.049,4.568,4.568   c0,2.516-2.049,4.564-4.568,4.564c-2.518,0-4.565-2.049-4.565-4.564C20.38,36.312,22.428,34.263,24.945,34.263z"/><path d="M74.422,42.931c0.729-1.201,1.172-2.596,1.172-4.1c0-4.395-3.574-7.969-7.966-7.969c-4.394,0-7.969,3.574-7.969,7.969   c0,4.393,3.575,7.965,7.969,7.965c1.646,0,3.178-0.502,4.448-1.361c1.01,0.73,1.621,1.738,1.621,2.799v10.084h3.4V48.233   C77.098,46.194,76.106,44.288,74.422,42.931z M67.628,43.396c-2.52,0-4.569-2.049-4.569-4.564c0-2.52,2.05-4.568,4.569-4.568   c2.518,0,4.565,2.049,4.565,4.568C72.193,41.347,70.146,43.396,67.628,43.396z"/></g></svg>',

					scrollSettings: {},
				},
				{
					title: this.getContent.navTitles[5][this.getLanguage],
					path: "/events",
					icon:
						'<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 1C5 0.447715 5.44772 0 6 0C6.55228 0 7 0.447715 7 1V1.89474H17.6315V1C17.6315 0.447715 18.0792 0 18.6315 0C19.1838 0 19.6315 0.447715 19.6315 1V1.93424C22.0948 2.2447 24 4.34723 24 6.89474V19C24 21.7614 21.7614 24 19 24H5C2.23858 24 0 21.7614 0 19V6.89473C0 4.13331 2.23858 1.89474 5 1.89474V1ZM19 3.89474H5C3.34314 3.89474 2 5.23788 2 6.89473V8.10526H22V6.89474C22 5.23788 20.6569 3.89474 19 3.89474ZM2 19V10.1053H22V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19Z"/></svg>',

					scrollSettings: {},
				},
			];
		},
	},
	metaInfo() {
		return {
			title: 'Lumos - Apply Now',
			meta: [
				{
					name: 'description',
					content: 'Bewirb dich jetzt und werde Teil von Lumos um dein Fachwissen im Bereich Data Science gemeinsam mit einer gleichgesinnten Community auszuweiten.'
				},
			]
		}
	},
	data() {
		return {
			base: null,
			notificationName: null,
			notificationEmail: null,
			notificationSent: false,
			notificationError: false,
			applicationOpen: false,
			publicPath: process.env.BASE_URL
		};
	},
	methods: {
		sendApplicationNotification() {

			const validateEmail = (email) => {
				const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
				console.log(re.test(email))
				return re.test(email);
			};
			
			// validation
			if (
				this.notificationName == null || this.notificationName.trim() === "" ||
				this.notificationEmail == null || this.notificationEmail.trim() === ""
			) {
				this.$toast.error(this.getContent.contact.buttonValidationError[this.getLanguage]);
				
			} else if (!validateEmail(this.notificationEmail)) {
				this.$toast.error(this.getContent.contact.buttonEmailError[this.getLanguage]);
				
			} else { // validation success
				let notify_btn = document.getElementById('applyNotifyButton');
				notify_btn.setAttribute("disabled", true);
				notify_btn.innerText = this.getContent.contact.buttonText[this.getLanguage];
				
				const data = {
					"Name": this.notificationName,
					"Email": this.notificationEmail,
				}
				
				fetch('/api/application_notify', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(data),
				}).then((response) => {
					response.json()
					this.notificationSent = true;
					if (response.status !== 200) {
						this.notificationError = true;
					}
				})
			}
		},
	},
	mounted() {
		//Statistic
		ManualCookies.sendCookies("Apply");
	}
}
</script>

<style lang="scss">
// center input-group and have submit button on same line as input

.lms-apply {
	background: white;

	.recruiting {
		min-height: 100vh;

		.head-mobile {
			display: none;
		}

		img {
			height: 30vw;
			max-height: 500px;
		}

		.text-box {
			text-align: center;
			width: 80%;
			min-width: 380px;
			margin: 0 auto 0 auto;
		}

		.lms-main-button {
			margin: 50px 0 0 0;
		}
	}

	.scroll-down {
		position: absolute;
		background: none;
		border: none;
		outline: none;
		cursor: pointer;
		bottom: 60px;
		transition: 0.3s ease-in-out;

		img {
			height: auto;
			width: 35px;
		}

		&:hover {
			transform: scale(1.1);
		}
	}


	.why-join {
		padding: 160px 0 60px 0;

		img {
			height: auto;
			width: 80%;
			margin: 50px 0 0 0;
		}
	}

	.what-we-look-for {
		height: 80vh;
		background: url("../assets/LookingForIllustration.svg") no-repeat center center;
		background-size: contain;
		margin-top: 100px;

		.lms-section-head {
			img {
				height: auto;
				width: 60%;
				margin: 0 0 0 30px;
			}

			h2 {
				font-size: 30px;
				padding: 0 0;
			}

			p {
				font-size: 16px;
				line-height: 26px;
				width: 80%;
				padding: 10px 0 0 0;
			}
		}
	}

	.application {
		padding: 100px 0 100px 0;

		.mobile {
			width: 100%;
			margin: 80px 0 60px 0;
		}

		.desktop {
			width: 80%;
			margin: 100px 0 70px 0;
		}

		h3 {
			margin-bottom: 2px;
		}
	}
}

.notify {
	margin-left: -30px;
}

.error-message {
	color: red;
}

/* - - - - - - - - - - - - - */
/* -  Mobile Optimization  - */
/* - - - - - - - - - - - - - */

@media screen and (max-width: 768px) {
	.lms-apply {
		.recruiting {
			img {
				display: none;
			}

			.text-box {
				width: 90%;
				min-width: 0;

				p {
					font-size: 18px;
				}

				img {
					display: block;
					height: 150px;
					margin: 0 auto 30px auto;
				}
			}
		}

		.why-join {
			padding: 100px 0;
		}

		.what-we-look-for {
			background: none;
			margin-top: 0;
			height: auto;

			.lms-section-head {
				text-align: left;

				h2 {
					font-size: 28px;
				}

				p {
					line-height: 25px;
					width: 100%;
					margin: 20px 0 0 0;
				}
			}
		}

		.application {
			padding: 80px 0 80px 0;

			.lms-content-frame {
				align-items: flex-start;
			}

			.timeline {
				width: 100%;
				margin: 40px 0 30px 0;
			}
		}
	}

	.notify {
		margin-left: 0;
	}
}

.Vue-Toastification__container {
	margin-top: 100px;
}

</style>
